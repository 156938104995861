export const reducers = {
  setPublishedLearningPath(state, action) {
    state.publishedLearningPath = action.payload;
  },
  setDraftLearningPath(state, action) {
    state.draftLearningPath = action.payload;
  },
  setAssignedLearningPath(state, action) {
    state.assignedLearningPath = action.payload;
  },
  setMyCreationLearningPath(state, action) {
    state.myCreationLearningPath = action.payload;
  },
  setOngoingLearningPath(state, action) {
    state.ongoingLearningPath = action.payload;
  },
  SetLoading(state, action) {
    state.isLoading = action.payload;
  },
  SetAssignedLearningPathLoading(state, action) {
    state.isAssignedLearningPathLoading = action.payload;
  },
  setShowAddLearningPathModal(state, action) {
    state.showAddLearningPathModal = action.payload;
  },
  setShowLearningPathAgendaModal(state, action) {
    state.showLearningPathAgendaModal = action.payload;
  },
  setShowDeleteLearningPathModal(state, action) {
    state.showDeleteLearningPathModal = action.payload;
  },

  setPrivateLearningPaths(state, action) {
    state.privateLearningPaths = action.payload;
  },

  setSelectedLearningPath(state, action) {
    state.selectedLearningPath = action.payload;
  },
  setSelectedLearningPathId(state, action) {
    state.selectedLearningPathId = action.payload;
  },
  setShowAssignLearningPathModal(state, action) {
    state.showAssignLearningPathModal = action.payload;
  },
  setShowRenameLearningPathModal(state, action) {
    state.showRenameLearningPathModal = action.payload;
  },
  setResetLearningPath(state) {
    state.isLoading = true;
  },
  setCurrentTab(state, action) {
    state.currentTab = action.payload;
  },
  // setPagination(state, action) {
  //   state.pagination = action.payload;
  // },
};
